
import Image1 from './device.png'

const Home = () =>{
  return (
    <div className="App">
      <header className="App-header">
        <img src={Image1} alt="Sorry Not Found" />
        <p>Sorry, this web app is currently being developed or undergoing scheduled maintenance. Please try again in 12-72 Hours.</p>
        <p>This placeholder is courtesy of SAAD LLC © 2024</p>
        <p>Contact the following for inquiries or purchasing this domain: contact@saad.services </p>
      </header>
    </div>
  );
}

export default Home