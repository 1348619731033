import './App.css';
import Home from './Home.jsx'
import Welcome from './Welcome.jsx'

import {
  Routes,
  Route
} from 'react-router-dom';

function App() {

  return(
    <div>
      <Routes>
        <Route element={<Home/>} path="/"/>
        <Route element={<Welcome/>} path="/Welcome"/>
      </Routes>
    </div>
  )

}

export default App;
