import Image1 from './device.png'

const Welcome = () =>{
    
    return(
        <div>
            <center>
                <p>Yes this was centered with center center</p>
                <img src={Image1} alt="Sorry Not Found" />
            </center>
        </div>
    )
}

export default Welcome